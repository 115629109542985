import {
  SELECT_SUBREDDIT,
} from 'D:/React App-coreui-admin-MNL/src/store/actions.js'

const initialState = {
flagModalIsOpen:false,
slideIndex:0,
showMap:true,
reduxFunction:function(){
	console.log("logging redux function")
},
}

const reducer = (state = initialState, action) => {
	const newState = {...state};
	
	switch(action.type) {
		case 'SLIDE_0':
		newState.slideIndex=0;
		break;
		case 'SLIDE_1':
		newState.slideIndex=1;
		break;
		case 'CITY_HOME_HK':
		newState.cityHome="Hong Kong";
		break;
		case 'CITY_HOME_MNL':
		newState.cityHome="Manila";
		break;
		case 'CITY_HOME_JK':
		newState.cityHome="Jakarta";
		break;
		case 'SHOW_MAP':
		newState.showMap=!state.showMap;
		break;
		case 'SHOW_SEARCH':
		newState.showSearch=!state.showSearch;
		break;
		case 'ZOOM_10':
		newState.zoom=10;
		break;
		case 'ZOOM_12':
		newState.zoom=12;
		break;	
		case 'REDUX_FUNCTION':
		return {reduxFunction: action.reduxFunction}
		break;	
		case 'HK_FLAG':
		newState.flagIcon="https://csmall.co.uk/app/assets/img/hongkong.png";
		break;	
		case 'PH_FLAG':
		newState.flagIcon="https://csmall.co.uk/app/assets/img/philippines.png";
		break;		
		case 'UK_FLAG':
		newState.flagIcon="https://csmall.co.uk/app/assets/img/uk.png";
		break;	
		case 'PH_FLAG_CLOSE':
		newState.flagModalIsOpen=!state.flagModalIsOpen;
		break;	
		case 'PH_FLAG_CLOSE_ALL':
		newState.flagModalIsOpen=false;
		break;			
		case 'ID_FLAG':
		newState.flagIcon="https://csmall.co.uk/app/assets/img/indonesia.png";
		break;	
		case 'REQ_CAIRO':
		newState.reqCity="Cairo";
		break;	
		case 'REQ_SP':
		newState.reqCity="São Paulo";
		break;	
		case 'REQ_KBL':
		newState.reqCity="Kabul";
		break;	
		case 'REQ_BGD':
		newState.reqCity="Baghdad";
		break;	
		case 'REQ_HK':
		newState.reqCity="Hong Kong";
		break;	
		case 'REQ_JK':
		newState.reqCity="Jakarta";
		break;	
		case 'REQ':
		newState.reqCity="";
		break;
		
	}
	
	return newState;
}

export default reducer;