import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import {createStore} from 'redux';
import reducer from './store/reducer';

import "typeface-roboto";
import "typeface-montserrat";
import "typeface-raleway";
import "typeface-muli";
import "typeface-open-sans";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-69783832-1');
ReactGA.pageview(window.location.pathname + window.location.search);



const store = createStore(reducer);

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
